<template>
  <b-row>
    <b-col cols="12">
      <b-overlay
        :show="$apollo.loading"
        spinner-variant="primary"
      >
        <b-card>
          <b-card-header
            class="justify-content-start p-0 pb-1 mb-1 border-bottom"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-secondary"
              @click="$router.back()"
            >
              <feather-icon
                class="mr-25"
                icon="ChevronLeftIcon"
              />
            </b-button>
            <h3 class="mb-0 ml-2">
              Round Details
            </h3>
          </b-card-header>

          <!-- Search Input -->
          <div class="custom-search d-flex justify-content-start">
            <b-form-group
              class="form-inline"
              label="Search"
              label-size="sm"
            >
              <b-form-input
                v-model="searchTerm"
                class="d-inline-block mr-1"
                placeholder="Search Rounds"
                type="text"
              />
            </b-form-group>
          </div>

          <!-- Table -->
          <vue-good-table
            :columns="columns"
            :pagination-options="{ enabled: true, perPage: pageLength }"
            :rows="filteredRounds"
            :search-options="{ enabled: true, externalQuery: searchTerm }"
          >
            <template
              slot="table-row"
              slot-scope="props"
            >
              <!-- Column: Organization -->
              <span v-if="props.column.field === 'organization'">
                {{ props.row.organization }}
              </span>

              <!-- Column: Score -->
              <span v-else-if="props.column.field === 'score'">
                <b-badge
                  :variant="props.row.score !== 'Pending' ? 'success' : 'light-secondary'"
                >
                  {{
                    props.row.score !== 'Pending'
                      ? 'Score: ' + props.row.score
                      : 'Pending'
                  }}
                </b-badge>
              </span>

              <!-- Column: Website Link -->
              <span v-else-if="props.column.field === 'website'">
                <b-link
                  :href="props.row.website"
                  target="_blank"
                >
                  {{ props.row.website }}
                </b-link>
              </span>

              <!-- Column: Action -->
              <span v-else-if="props.column.field === 'action'">
                <b-button
                  :to="{ name: 'mentor-application-score', params: { pid: $route.params.id, apid: $route.params.sid, aid: props.row.id, rid: $route.params.pid } }"
                  class="mr-50"
                  size="sm"
                  variant="outline-primary"
                >
                  {{ props.row.score !== 'Pending' ? 'View Score' : 'Review' }}
                </b-button>
              </span>
            </template>
            <!-- pagination -->
            <template
              slot="pagination-bottom"
              slot-scope="props"
            >
              <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                  <span class="text-nowrap ">
                    Showing 1 to
                  </span>
                  <b-form-select
                    v-model="pageLength"
                    :options="[3, 5, 10]"
                    class="mx-1"
                    @input="(value)=>props.perPageChanged({currentPerPage:value})"
                  />
                  <span class="text-nowrap"> of {{ props.total }} entries </span>
                </div>
                <div>
                  <b-pagination
                    :per-page="pageLength"
                    :total-rows="props.total"
                    :value="1"
                    align="right"
                    class="mt-1 mb-0"
                    first-number
                    last-number
                    next-class="next-item"
                    prev-class="prev-item"
                    @input="(value)=>props.pageChanged({currentPage:value})"
                  >
                    <template #prev-text>
                      <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                      />
                    </template>
                  </b-pagination>
                </div>
              </div>
            </template>
          </vue-good-table>
        </b-card>
      </b-overlay>
    </b-col>
  </b-row>
</template>

<script>
import {
  BBadge,
  BButton,
  BCard,
  BCardHeader,
  BCol,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BLink,
  BOverlay,
  BPagination,
  BRow,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import gql from 'graphql-tag'

export default {
  components: {
    BFormSelect,
    BPagination,
    BCard,
    BCardHeader,
    BOverlay,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    BLink,
    BBadge,
    VueGoodTable,
  },
  data() {
    return {
      applications: [], // Array to store round data
      searchTerm: '', // Search term for filtering rounds
      pageLength: 5, // Number of rows per page
      columns: [
        {
          label: 'Organization',
          field: 'organization',
        },
        {
          label: 'Score',
          field: 'score',
          sortable: false,
        },
        {
          label: 'Website Link',
          field: 'website',
          sortable: false,
        },
        {
          label: 'Action',
          field: 'action',
          sortable: false,
        },
      ],
    }
  },
  computed: {
    /**
     * Filters and maps the rounds data to include necessary fields for the table.
     * @returns {Array} Filtered rounds data.
     */
    filteredRounds() {
      return this.applications.map(round => ({
        ...round,
        organization: round.users_organizationtable ? round.users_organizationtable.title : 'N/A',
        score: round.programs_applicationscorestables?.length
          ? round.programs_applicationscorestables[0].direct_score
          : 'Pending',
        website: round.users_organizationtable?.url,
      }))
    },

  },
  apollo: {
    applications: {
      /**
       * GraphQL query to fetch rounds data.
       * @returns {Object} GraphQL query object.
       */
      query() {
        return gql`
      query GetRoundDetails($programId: Int!, $partnerId: Int!) {
        programs_applicantstable(where: { program_id: { _eq: $programId } }) {
          id
          users_organizationtable {
            title
            url
          }
          programs_applicationscorestables(
            where: { direct_score: { _is_null: false }, partner_id: { _eq: $partnerId } }
          ) {
            direct_score
          }
        }
      }
    `
      },
      /**
       * Variables for the GraphQL query.
       */
      variables() {
        return {
          programId: this.$route.params.id,
          partnerId: this.$route.params.pid,
        }
      },
      /**
       * Updates the rounds data after fetching from the server.
       * @param {Object} data - The fetched data.
       * @returns {Array} Sorted rounds data.
       */
      update: data => data.programs_applicantstable.sort(
        (a, b) => {
          const aLength = a.programs_applicationscorestables?.length ? a.programs_applicationscorestables.length : 0
          const bLength = b.programs_applicationscorestables?.length ? b.programs_applicationscorestables.length : 0
          return aLength - bLength
        },
      ),
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
